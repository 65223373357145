body {
  background: #ddd;
  font: 16px "Helvetica Neue", Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
  margin: 15px 0;
  text-align: center;
}

header h1 {
  font: 36px "Georgia";
  font-weight: bold;
  letter-spacing: -0.03em;
  margin: 0;
  padding: 0;
}

header p {
  margin: 0;
  padding: 0;
}

footer {
  color: #444;
  font-size: 12px;
  text-align: center;
  position: absolute;
  bottom: 15px;
  width: 100%;
}

footer a {
  text-decoration: none;
}

button {
  color: #000;
  font-size: 16px;
  border: 0;
  cursor: pointer;
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  background: none;
}

button:active {
  color: #444;
}

.hide {
  display: none;
}

.game {
  margin: 0 auto;
  width: 320px;
}

.status {
  margin: 15px 0;
  text-align: center;
}

.board {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1px;
}

.tile {
  background: #fff;
  display: inline-flex;
  font-size: 30px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  user-select: none;
}

.tile:active {
  background-color: #eee;
}

@media (hover) {
  .tile:hover {
    color: #444;
    background-color: #eee;
  }
}

.tile.selected {
  color: #00e;
  background-color: #eee;
}

.tile.available {
  cursor: pointer;
}

.tile.submitted {
  color: #888;
  background-color: #ddd;
}

.guess {
  font-size: 32px;
  margin: 15px 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}

.guess .word {
  font-weight: bold;
}

.guess button {
  font-size: 32px;
  position: absolute;
  right: 0;
  top: 0;
}

.guess .accepted {
  animation-name: bounce;
  animation-duration: 600ms;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.guess .rejected {
  animation-name: shake;
  animation-duration: 600ms;
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-1px);
  }

  20%,
  80% {
    transform: translateX(2px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-4px);
  }

  40%,
  60% {
    transform: translateX(4px);
  }
}

.history {
  color: #888;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
}

.history li {
  margin-bottom: 5px;
}

.history button {
  color: #888;
}

.history button:active {
  color: #888;
}
